html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Sans-serif;
}
* {
    box-sizing: border-box;
}
a {
    color: black;
    text-decoration: none;
}