.uploader {
    display: flex;
    align-items: stretch;
    padding: 10px;
    margin-top: 10px;
    flex-direction: column;
}

.uploadFilIcon {
    font-size: 36px;
}

.uploadButtons {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.speaker {
    background-color: #FFF;

    &.s1 {
        color: #3357FF;
    }

    &.s2 {
        color: #23c412;
    }

    &.s3 {
        color: #FF33A5;
    }

    &.s4 {
        color: #85681a;
    }

    &.s5 {
        color: #52187e;
    }
}